import React from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';

//Стили
import '../css/down-menu.css';

//Языки / переводы
import translationStore from '../locales/locales';


const DownMenu = observer(() => {
    //Языки / переводы
    const trnsl = translationStore.trnsl;

    return (
        <>
            <div className='down_menu'>
                <ul>
                    <li><Link to="/mine"><div className='icon icon_mine'></div><span>{trnsl[trnsl.user_lang].mine}</span></Link></li>
                    <li><Link to="/tasks"><div className='icon icon_tasks'></div><span>{trnsl[trnsl.user_lang].tasks}</span></Link></li>
                    <li><Link to="/"><div className='icon icon_home'></div><span>{trnsl[trnsl.user_lang].home}</span></Link></li>
                    <li><Link to="/frends"><div className='icon icon_frends'></div><span>{trnsl[trnsl.user_lang].frends}</span></Link></li>
                    <li><Link to="/id"><div className='icon icon_id'></div><span>ID</span></Link></li>
                </ul>
            </div>
        </>
    );
});

export default DownMenu;
