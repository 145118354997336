import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { observer } from "mobx-react-lite";

//Конфиг
import config from '../config';

//Стили
import '../css/iduser.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';


//Компоненты
import TopHeader from '../components/TopHeader';


const Iduser = observer(() => {

    //Языки / переводы
    const tr = translationStore.trnsl;


    return (
        <>
            <div className='slot_machine_flex_top'>
                <TopHeader />
            </div>

            <div className='iduser_main'>
                <div className='iduser_mainwrap'>
                    <img src="/images/moneta.png" style={{ width: '80px', height: '60px' }} />
                    {tr[tr.user_lang].coming_soon}
                </div>
            </div>

        </>
    );
})

export default Iduser;
