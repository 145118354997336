import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { observer } from "mobx-react-lite";

//Конфиг
import config from '../config';

//Стили
import '../css/referals.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';
import TasksStore from '../store/tasksStore';


//Компоненты
import TopHeader from '../components/TopHeader';
import Banners from '../components/Banners';
import ReferalLink from '../components/ReferalLink';
import TemplateReferal from '../components/TemplateReferal';
import TemplateReferalTwoLine from '../components/TemplateReferalTwoLine';
import Giftfriend from '../components/Giftfriend';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Referals = observer(() => {

    const [referals, setReferals] = useState(null);

    const referalsList = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/getreferals';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                //console.log("СПИСОК РЕФЕРАЛОВ: ", JSON.stringify(response.data, null, 1));
                setReferals(response.data);
                console.log('СПИСОК РЕФЕРАЛОВ: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });

    }


    //Языки / переводы
    const tr = translationStore.trnsl;


    useEffect(() => {
        referalsList();
    }, []);

    //Переключение реферальный линий
    const [line, setLine] = useState('1');

    const handlerLine = (ln) => {
        setLine(ln);
    }

    //Кнопка обновить
    const [update, setUpdate] = useState(false); //Для кнопки обновить
    useEffect(() => {
        if (update === true) {
            referalsList();
            toast.success('Referrals have been updated!', {
                position: "bottom-center",
                autoClose: 3100,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
            setTimeout(() => {
                setUpdate(false);//Обновляем данные пользователя
            }, 3000); //Обновить будет возможно раз в 3 секунды
        }
    }, [update]);

    //Приглашения для пользователей
    const handleInvite = () => {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;

        // Сообщение и ссылка
        const inviteMessage = tr[tr.user_lang].invite_text;
        const inviteLink = `https://t.me/${config.botName}?start=${PlayerStore.player.refcode}`;

        // Полная ссылка для пересылки
        //const fullInviteLink = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(inviteMessage)}`;
        const fullInviteLink = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}`;

        // Открытие ссылки на пересылку сообщения с приглашением
        window.Telegram.WebApp.openLink(fullInviteLink);
    };

    //Для Gift
    const [openGift, setOpenGift] = useState(false); //Для открытия окна подарка gift

    const handleOpenGift = (value) => {//Калбек функция для откртытия или закрытия модально окно с подарком gift
        setOpenGift(value);
    };

    // Используем useRef для каждого input
    const giftRefs = useRef([]);


    // Функция копирования в буфер обмена
    const copyToClipboard = (index) => {
        if (giftRefs.current[index]) {
            giftRefs.current[index].select();
            document.execCommand('copy');
            toast.success(tr[tr.user_lang].support_copy_link, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
        }
    };

    return (
        <>
            <div className='slot_machine_flex_top'>
                <TopHeader />
                <div className='task_title'>
                    {tr[tr.user_lang].referals}
                </div>
                <div className='task_subtitle'>
                    {tr[tr.user_lang].bonus}
                </div>
            </div>

            <div className='referals_main'>

                <Banners />

                <ReferalLink />

                <div className='my_referals_row'>
                    <button className={'button_line_active'} onClick={handleInvite}>
                        {tr[tr.user_lang].invite_frends}
                    </button>
                    <button className={'button_line_blue'} onClick={() => setOpenGift(true)}>
                        {tr[tr.user_lang].support_a_friend}
                    </button>
                </div>



                {PlayerStore.player.gifts && PlayerStore.player.gifts.length > 0 ? (
                    <div className='my_referals'>
                        <div className='my_referals_title'>
                            {tr[tr.user_lang].support_gifts}
                        </div>
                        <div>
                            {PlayerStore.player.gifts.map((gift, index) => (
                                <div className='giftItem' key={gift.id}>
                                    <div className='giftItemTitle'>
                                        {tr[tr.user_lang].support_gift} {gift.amount}
                                        <img src='/images/moneta.png' className='gift_icon_monets' alt="monets" />
                                    </div>
                                    <div className='referal_link_row'>
                                        <input
                                            ref={el => giftRefs.current[index] = el} // Присваиваем реф для каждого input
                                            value={`${config.telegramRefer}${PlayerStore.player.refcode}-${gift.gift_kod}`}
                                            readOnly
                                            className='referal_link_input'
                                        />
                                        <button
                                            onClick={() => copyToClipboard(index)} // Передаем индекс в функцию
                                            className='referal_link_copybutton'
                                        >
                                            <img src='/images/icon-copy.svg' className='icon-copy' alt="copy icon" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}


                <div className='my_referals'>
                    <div className='my_referals_title'>
                        {tr[tr.user_lang].my_referals}
                    </div>

                    <div className='my_referals_buttons'>
                        <button
                            className={line == '1' ? 'button_line_active' : 'button_line'}
                            onClick={() => handlerLine('1')}
                        >
                            1 {tr[tr.user_lang].line}
                        </button>
                        <button
                            className={line == '2' ? 'button_line_active' : 'button_line'}
                            onClick={() => handlerLine('2')}
                        >
                            2 {tr[tr.user_lang].line}
                        </button>

                        <button
                            className='button_line_rotate'
                            onClick={() => setUpdate(true)}
                        >
                            <img src="/images/icon-rotate.svg" style={{ width: '26px', height: '26px' }} />
                        </button>
                    </div>


                    <div className='refaral_user_list'>
                        {Array.isArray(referals) && referals.map(referal =>
                            line === '1' ? (
                                <TemplateReferal
                                    key={referal.id}
                                    referal={referal}
                                />
                            ) : line === '2' ? (
                                <TemplateReferalTwoLine
                                    key={referal.id}
                                    referal={referal}
                                />
                            ) : null
                        )}

                    </div>

                </div>

            </div>

            <Giftfriend open={openGift} onOpenChange={handleOpenGift} />
        </>
    );
})

export default Referals;
