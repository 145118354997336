import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { isMobile } from 'react-device-detect';
import axios from 'axios';

import effectStore from './store/effectStore'; // Триггер для срабатывания useEffect в App.js из дочерних компонентов

//Конфиг
import config from './config';

//Стили
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//Экраны (страницы)
import Home from './screens/Home';
import Tasks from './screens/Tasks';
import Referals from './screens/Referals';
import Iduser from './screens/Iduser';
import Mine from './screens/Mine';

//Компоненты
import Preloader from './components/Preloader';
import DownMenu from './components/DownMenu';
import StartBanners from './components/StartBanners';

//Сторы
import PlayerStore from './store/playerStore';
import TasksStore from './store/tasksStore';

const apiUrl = config.apiBaseUrl + '/api/api/v1/login';

const App = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Эмуляция загрузки данных
    setTimeout(() => {
      setLoading(false);
    }, 4000);

  }, []);

  //Подсоединяем файл телеграмм======== window.Telegram.WebApp.platform
  const [currentDevice, setCurrentDevice] = useState(null);
  const [error, setError] = useState(null);

  const loadTelegramScript = () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById('telegram-web-app-script')) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'telegram-web-app-script';
      script.src = "https://telegram.org/js/telegram-web-app.js";
      script.async = true;

      script.onload = resolve;
      script.onerror = reject;

      document.head.appendChild(script);
    });
  };

  const location = useLocation();
  useEffect(() => {
    console.log("useEffect triggered");

    const loadTelegramScript = () => {
      return new Promise((resolve, reject) => {
        if (document.getElementById('telegram-web-app-script')) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.id = 'telegram-web-app-script';
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.async = true;

        script.onload = resolve;
        script.onerror = reject;

        document.head.appendChild(script);
      });
    };

    loadTelegramScript()
      .then(() => {
        console.log("Telegram script loaded");

        if (window.Telegram && window.Telegram.WebApp) {
          let tg = window.Telegram.WebApp;

          setCurrentDevice(tg.platform);

          // Делаем запрос и получаем объект пользователя
          const apiUrl = config.apiBaseUrl + '/api/api/v1/login';

          // Заголовки запроса
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          };

          const idchat = String(tg.initDataUnsafe.user.id); // Получаем idchat
          console.log('idchat:::: ', idchat);

          // Проверяем, что idchat существует
          if (idchat) {
            axios.post(apiUrl, { idchat }, { headers })
              .then(response => {
                console.log("ПОЛЬЗОВАТЕЛЬ: ", JSON.stringify(response.data, null, 1));
                PlayerStore.setPlayer(response.data.user);
                // Обновляем PlayerStore

                PlayerStore.editPlayerStore('tguser', tg.initDataUnsafe.user);
              })
              .catch(error => {
                if (error.response) {
                  // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                  console.error('Ошибка:', error.response.data);
                  setError(`Ошибка получения данных: ${error.response.data.message}`);

                } else if (error.request) {
                  // Запрос был сделан, но ответ не был получен
                  console.error('Ошибка запроса:', error.request);
                  setError('Ошибка сети. Пожалуйста, попробуйте снова.');
                } else {
                  // Ошибка при настройке запроса
                  console.error('Ошибка:', error.message);
                  setError(`Ошибка запроса: ${error.message}`);
                }
              });
          } else {
            setError('Не удалось получить idchat из Telegram WebApp!');
          }

          tg.expand(); // Раскрываем приложение Telegram на всю высоту
          console.log('PlayerStore===>: ', JSON.stringify(PlayerStore.player));
        } else {
          setError('Ошибка загрузки Telegram WebApp!');
        }
      })
      .catch(() => {
        setError('Ошибка загрузки скрипта Telegram WebApp!');
      });

  }, [location, effectStore.shouldTriggerEffect]); // useEffect будет срабатывать при каждом изменении location
  //===================================



  if (currentDevice != 'android' && currentDevice != 'ios') {
    return (
      <div style={{
        display: 'flex',
        backgroundColor: '#000000',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        color: '#ffffff'
      }}>
        <div>Open this app on your mobile device</div>
      </div>
    );
  }



  return (
    <div className='main'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mine" element={<Mine />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/frends" element={<Referals />} />
        <Route path="/id" element={<Iduser />} />
      </Routes>




      <DownMenu />


      {loading ? <Preloader /> : null}
    </div>

  );
});

export default App;
