import { makeObservable, observable, action } from 'mobx';

class TranslationStore {

    trnsl = {
        user_lang: 'en',
        en: {
            home: 'Home',
            tasks: 'Tasks',
            mine: 'ByBit',
            frends: 'Frends',
            at_you: 'At you',
            scrolling: 'scrolling',
            beggar: 'Beggar',
            janitor: 'Janitor',
            worker: 'Worker',
            engineer: 'Engineer',
            it_technician: 'IT technician',
            cryptan: 'Cryptan',
            top_Manager: 'TOP Manager',
            seo: 'SEO',
            major: 'Major',
            banker: 'Banker',
            president: 'President',
            you_balance: 'Your balance',
            do_it: 'Complete tasks and get extra spins and tokens!',
            subscribe: 'Subscribe',
            bybitref_link: 'Registration on ByBit',
            passed: 'Passed',
            check: 'Check',
            referals: 'Referals',
            bonus: 'Invite a friend and you and your friend will receive bonuses!',
            invite_link: 'My invite link',
            my_referals: 'My referals',
            line: 'line',
            invite: 'Invite',
            no_referals: 'You dont have referals yet',
            invite_text: 'Play with me and earn tokens.',
            invite_frends: 'Invite friends',
            coming_soon: 'Coming soon',
            support_a_friend: 'Gift for friend',
            support_introtext: 'Send a referral link and choose a gift for your friend. Boost your karma!',
            support_create: 'Create',
            support_gift: 'Gift',
            support_gifts: 'Gifts',
            support_copy_link: 'The link has been copied!',
            is_not_balance: 'There is not enough balance',
            support_link_already_exists: 'Error! The link has already been created or you dont have enough balance',
            weblink: 'Follow the link',
            wait: 'Wait',
        },
        ru: {
            home: 'Главная',
            tasks: 'Задания',
            mine: 'ByBit',
            frends: 'Друзья',
            at_you: 'У Вас',
            scrolling: 'прокрутов',
            beggar: 'Нищеброд',
            janitor: 'Дворник',
            worker: 'Рабочий',
            engineer: 'Иженер',
            it_technician: 'IT-шник',
            cryptan: 'Криптан',
            top_Manager: 'ТОП-менеджер',
            seo: 'CEO',
            major: 'Мажор',
            banker: 'Банкир',
            president: 'Президент',
            you_balance: 'Ваш баланс',
            do_it: 'Выполняй задания и получай дополнительные спины и токены!',
            subscribe: 'Подписаться',
            bybitref_link: 'Регистрация на ByBit',
            passed: 'Пройден',
            check: 'Проверить',
            referals: 'Рефералы',
            bonus: 'Пригласи друга и вы и ваш друг получите бонусы!',
            invite_link: 'Моя реф. ссылка',
            my_referals: 'Мои рефералы',
            line: 'линия',
            invite: 'Пригласить',
            no_referals: 'У Вас ещё нет рефералов',
            invite_text: 'Играй со мной, и зарабатывай токены.',
            invite_frends: 'Пригласи друзей',
            coming_soon: 'Скоро будет',
            support_a_friend: 'Поддержи друга',
            support_introtext: 'Отправь реферальную ссылку и выбери подарок своему другу. Прокачай свою карму!',
            support_create: 'Создать',
            support_gift: 'Подарок',
            support_gifts: 'Подароки',
            support_copy_link: 'Ссылка скопирована!',
            is_not_balance: 'Не хватает монет на балансе',
            support_link_already_exists: 'Ошибка! Ссылка уже создана или у Вас не хватает баланса',
            weblink: 'Перейти по ссылке',
            wait: 'Ждите',
        }
    };


    constructor() {
        makeObservable(this, {
            trnsl: observable,
            setTranslations: action
        });
    }

    setTranslations(lang, trnsl) {
        this.trnsl[lang] = trnsl;
    }
}

const translationStore = new TranslationStore();
export default translationStore;