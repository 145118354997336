import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import { observer } from "mobx-react-lite";
import { useLocation } from 'react-router-dom';

//Конфиг
import config from '../config';

//Стили
import '../css/task.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';
import TasksStore from '../store/tasksStore';


//Компоненты
import TopHeader from '../components/TopHeader';
import TemplateTask from '../components/TemplateTask';
import SlideBottomPanel from '../components/SlideBottomPanel';

import { Modal } from 'react-bootstrap';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Tasks = observer(() => {
    //Ссылка на ByBit рефку
    const Bybit_ref = 'https://www.bybit.com/ru-RU/sign-up?affiliate_id=64385&group_id=509922&group_type=1&ref_code=VABANK';

    //Языки / переводы
    const tr = translationStore.trnsl;

    const [task, setTask] = useState(null); //Стейт который будет отображатся в сплывающей панеле 
    const handleTaskClick = (task) => {
        console.log("Клик по задаче:", task);
        setTask(task);
        getbybitlink(task.id);
        getbybitlinkparent(task.id);
        openPanel();
    };

    ////Для всплывающей панели  SlideBottomPanel=======
    const [isPanelOpen, setPanelOpen] = useState(false);
    const openPanel = () => {
        setPanelOpen(true);
    };

    const closePanel = () => {
        setPanelOpen(false);
    };
    ///=======


    //Запускаем функцию taskList из стора, которая берет список заданий с сервера
    useEffect(() => {
        // Вызов taskList независимо от того, смонтирован ли Tasks.js
        TasksStore.taskList();
    }, []);

    const telegramConfirm = (id, check) => {
        console.log('id: ', id, 'check: ', check);
        const apiUrl = config.apiBaseUrl + '/api/api/v1/telegramconfirm';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'id_task': id,
            'chat_id': check,
            'user_id': user_id
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ПОДТВЕРЖДЕНИЕ ПОДПИСКИ: ', JSON.stringify(response.data, null, 1));
                if (response.data.data.result.status !== 'left' && response.data.data.ok === true) {
                    console.log('response.data.data.ok: ', JSON.stringify(response.data.data, null, 1));
                    TasksStore.updateCompletedStatusById(response.data.task.id, true);//Изменяем стор если прошёл задание

                    toast.success('SUCCESS!', {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                    setTimeout(() => {
                        closePanel(); // Обновляем данные через 3 секунды
                    }, 1000);

                }

            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Подтверждение прохождения задания
    const taskConfirm = (id) => {
        console.log('id: ', id);
        const apiUrl = config.apiBaseUrl + '/api/api/v1/taskcheck';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        const chat_id = PlayerStore.player.idchat; // Получаем id пользователя

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'id_task': id,
            'user_id': user_id,
            'chat_id': chat_id
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ПОДТВЕРЖДЕНИЕ ПРОХОЖДЕНИЯ ЗАДАНИЯ: ', JSON.stringify(response.data, null, 1));
                setTask(response.data.task);
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    //Забрать награду
    const taskCollectReward = (id) => {
        console.log('id: ', id);
        const apiUrl = config.apiBaseUrl + '/api/api/v1/taskCollectReward';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        const chat_id = PlayerStore.player.idchat; // Получаем id пользователя

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'id_task': id,
            'user_id': user_id,
            'chat_id': chat_id
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ЗАБРАТЬ НАГРАДУ: ', JSON.stringify(response.data, null, 1));
                setTask(response.data.task);
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
                if (!response.data.error) {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }


    //Проверяем параметры в ссылках  useParams
    const location = useLocation();
    const { taskType } = location.state || {};

    useEffect(() => {
        //if (taskType == 'telegram') {
        console.log('taskType', taskType);
        //}
    }, [taskType]);

    //Для формы отправики BID
    const [inputValue, setInputValue] = useState('');
    //const [formData, setFormData] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        //setFormData(inputValue); // Записываем данные из поля ввода в состояние
        //alert(`Данные сохранены: ${inputValue}`);
        submitForm(inputValue, task.id);
    };

    const submitForm = (value, task_id) => {

        const apiUrl = config.apiBaseUrl + '/api/api/v1/tasksubmitform';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        console.log('value: ', value, 'task_id: ', task_id, 'idchat: ', user_id);

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'task_id': task_id,
            'user_id': user_id,
            'bybit_link': value,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ОТПРАВКА ФОРМЫ: ', JSON.stringify(response.data, null, 1));
                if (response.data.status == 'success') {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });

                    setTask((prevTask) => ({
                        ...prevTask, // Сохраняем предыдущие данные задачи
                        bybit_link: response.data.bybit // Добавляем новый ключ bybit с его значением
                    }));

                    //setTimeout(() => {
                    //    closePanel(); // Обновляем данные через 3 секунды
                    //}, 1000);
                }
                if (response.data.status == 'error') {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка отправки формы 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка отправки формы:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка отправки формы:', error.message);
                }
            });
    }

    //Для модально окна замены ссылки Bybit openbybitmodal
    const [openbybitmodal, setOpenbybitmodal] = useState(false);

    const handleCloseBybitmodal = () => { //Закрыть модальное окно ByBit смены ссылки
        setOpenbybitmodal(false);
    }

    const handlemodalBybit = (task) => { //Открыть модальное окно ByBit смены ссылки
        getbybitlink(task.id);
        getbybitlinkparent(task.id);
        setOpenbybitmodal(true);
        setTask(task);
    }


    //Для формы ByBit замены ссылки
    const [inputValueByBit, setInputValueByBit] = useState('');
    const [isDisabled, setIsDisabled] = useState(false); //Для временной блокировки кнопки сохранить ссылку


    const handleSubmitByBitform = (event) => {
        event.preventDefault();
        console.log('Отправка формы ByBit!!! ', inputValueByBit);
        //setFormData(inputValue); // Записываем данные из поля ввода в состояние
        //alert(`Данные сохранены: ${inputValue}`);
        //submitFormByBit(inputValueByBit, task.id);
        submitFormByBit(inputValueByBit, task.id);

        setIsDisabled(true);//Временно отключаем кнопку
        setTimeout(() => {
            setIsDisabled(false);
        }, 3000);
    };
    //Отправляем ссылку на сервер
    const submitFormByBit = (value, task_id) => {

        const apiUrl = config.apiBaseUrl + '/api/api/v1/taskformbybit';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        console.log('value: ', value, 'task_id: ', task_id, 'idchat: ', user_id);

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'task_id': task_id,
            'user_id': user_id,
            'bybit_reflink': value,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ОТПРАВКА ФОРМЫ ByBIt замены ссылки: ', JSON.stringify(response.data, null, 1));
                if (response.data.status == 'success') {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });

                    setTimeout(() => {
                        setInputValueByBit(); // Обновляем данные через 3 секунды
                    }, 2000);
                }
                if (response.data.status == 'error') {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка отправки формы 2xx:', error.response.data);
                    toast.info(error.response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка отправки формы:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка отправки формы:', error.message);
                }
            });
    }

    //Получаем ссылку ByBit
    const getbybitlink = (task_id) => {

        const apiUrl = config.apiBaseUrl + '/api/api/v1/getbybitlink';
        const token = PlayerStore.player.token; // Получаем токен
        const user_id = PlayerStore.player.idchat; // Получаем id пользователя
        //console.log('value: ', value, 'task_id: ', task_id, 'idchat: ', user_id);

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            'task_id': task_id,
            'user_id': user_id,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('Получение ссылки ByBit: ', JSON.stringify(response.data, null, 1));
                if (response.data.status == 'success') {
                    setInputValueByBit(response.data.bybit.bybit_reflink);
                    console.log('response.data.bybit.bybit_reflink :::', response.data.bybit.bybit_reflink);
                    setInputValueByBitParent(response.data.bybitrow.bybit_reflink);
                    console.log('response.data.bybitrow.bybit_reflink :::', response.data.bybitrow.bybit_reflink);
                }
                if (response.data.status == 'error') {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Slide,
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка отправки формы 2xx:', error.response.data);

                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка отправки формы:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка отправки формы:', error.message);
                }
            });
    }

    //Если Parent User поменял реферальную ссылку ByBit
    const [inputValueByBitParent, setInputValueByBitParent] = useState(null);

    //Получаем ссылку ByBit от Parent User
    const getbybitlinkparent = (task_id) => {
        if (task_id) {
            const apiUrl = config.apiBaseUrl + '/api/api/v1/getbybitlink';
            const token = PlayerStore.player.token; // Получаем токен
            //const user_id = PlayerStore.player.idchat; // Получаем id пользователя
            const parent_id = PlayerStore.player.parent_user; // Получаем id parent user пользователя
            //console.log('value: ', value, 'task_id: ', task_id, 'idchat: ', user_id);

            // Заголовки запроса
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
            };
            //Отправляемые даныне
            const dataRec = {
                'task_id': task_id,
                'user_id': parent_id,
            };

            axios.post(apiUrl, dataRec, { headers })
                .then(response => {
                    console.log('Получение ссылки ByBit Pаrent: ', JSON.stringify(response.data, null, 1));
                    if (response.data.status == 'success') {
                        setInputValueByBitParent(response.data.bybitrow.bybit_reflink);
                        console.log('response.data.bybit.bybit_reflink::=>', response.data.bybitrow.bybit_reflink);
                    }
                    if (response.data.status == 'error') {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Slide,
                        });
                    }
                })
                .catch(error => {
                    if (error.response) {
                        // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                        console.error('Ошибка отправки формы 2xx:', error.response.data);

                    } else if (error.request) {
                        // Запрос был сделан, но ответ не был получен
                        console.error('Ошибка отправки формы:', error.request);
                    } else {
                        // Ошибка при настройке запроса
                        console.error('Ошибка отправки формы:', error.message);
                    }
                });
        }
        else {
            toast.error('Error!', {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
        }
    }

    //Получить вознаграждение от ByBit
    const [succesRevardByBit, setSuccesRevardByBit] = useState(false);
    const getRevardBybit = (task_id) => {
        if (task_id) {
            const apiUrl = config.apiBaseUrl + '/api/api/v1/getrewardtime';
            const token = PlayerStore.player.token; // Получаем токен
            const user_id = PlayerStore.player.idchat; // Получаем id пользователя
            //const parent_id = PlayerStore.player.parent_user; // Получаем id parent user пользователя
            //console.log('value: ', value, 'task_id: ', task_id, 'idchat: ', user_id);

            // Заголовки запроса
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
            };
            //Отправляемые даныне
            const dataRec = {
                'task_id': task_id,
                'user_id': user_id,
            };

            axios.post(apiUrl, dataRec, { headers })
                .then(response => {
                    if (response.data.status == 'success') {
                        console.log('Вознаграждение за UID ByBit: ', JSON.stringify(response.data, null, 1));
                        if (response.data.collect_reward == 1 && response.data.bybitrow.getting_reward == 1) {
                            setSuccesRevardByBit(true);
                            toast.success(response.data.message, {
                                position: "bottom-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                                transition: Slide,
                            });
                        }

                    }
                    if (response.data.status == 'error') {
                        toast.error(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Slide,
                        });
                    }
                })
                .catch(error => {
                    if (error.response) {
                        // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                        console.error('Ошибка получения вознаграждения за UID 2xx:', error.response.data);

                    } else if (error.request) {
                        // Запрос был сделан, но ответ не был получен
                        console.error('Ошибка получения вознаграждения за UID:', error.request);
                    } else {
                        // Ошибка при настройке запроса
                        console.error('Ошибка получения вознаграждения за UID:', error.message);
                    }
                });
        }
        else {
            toast.error('Error!', {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
        }
    }

    return (
        <>
            <div className='slot_machine_flex_top'>
                <TopHeader />
                <div className='task_title'>
                    {tr[tr.user_lang].tasks}
                </div>
                <div className='task_subtitle'>
                    {tr[tr.user_lang].do_it}
                </div>
            </div>

            <div className='tast_main'>
                <div className='tast_mainwrap'>
                    {Array.isArray(TasksStore?.tasks) && TasksStore?.tasks.map(task => (
                        task.enabled == 1 ? (
                            <TemplateTask
                                key={task.id}
                                task={task}
                                click={() => handleTaskClick(task)}
                                modalBybit={() => handlemodalBybit(task)}
                            />
                        ) : null
                    ))}
                </div>
            </div>

            {task ? (
                <SlideBottomPanel isOpen={isPanelOpen} onClose={closePanel}>
                    <div className='task_bottom_panel_wrap'>
                        <div className='task_bottom_panel_image'>
                            <img src={task.image} style={{ width: '80px', height: 'auto' }} />
                        </div>
                        <div className='task_bottom_panel_title'>
                            {tr.user_lang == 'ru' ? task.title : task.title_en}
                        </div>

                        {task.type == 'confirmation' || task.type == 'without_confirmation' ? (
                            <>
                                <div className='task_bottom_panel_button'>
                                    <a href={task.link} rel="noopener noreferrer" target="_blank" className="button">
                                        {tr[tr.user_lang]['subscribe']}
                                    </a>
                                </div>

                                <div className='task_bottom_panel_button'>
                                    {task.check && task.completed_by_current_user === true ? (
                                        <button onClick={() => console.log('Пройдено!')} className="buttonBigGreen">
                                            {tr[tr.user_lang]['passed']}
                                        </button>
                                    ) : task.check ? (
                                        <button onClick={() => telegramConfirm(task.id, task.check)} className="buttonBig">
                                            {tr[tr.user_lang]['check']}
                                        </button>
                                    ) : null}
                                </div>

                                <div className='task_bottom_panel_button'>
                                    {!task.check && task.collect == 0 ? (
                                        <button onClick={() => taskConfirm(task.id)} className="buttonBig">
                                            {tr[tr.user_lang]['check']}
                                        </button>
                                    ) : !task.check && task.completed_by_current_user === true && task.is_check_time_in_future === true && task.collect == 1 || !task.check && task.collect == 1 && task.later == 1 ? (
                                        <button onClick={() => taskCollectReward(task.id)} className="buttonBig">
                                            Collect the reward later
                                        </button>
                                    ) : !task.check && task.is_check_time_in_future === false && task.collect == 1 ? (
                                        <button onClick={() => taskCollectReward(task.id)} className="buttonBig">
                                            To collect the reward
                                        </button>
                                    ) : !task.check && task.collect == 2 ? (
                                        <button onClick={() => console.log('Success!')} className="buttonBigGreen">
                                            {tr[tr.user_lang]['passed']}
                                        </button>
                                    ) : null}
                                </div>
                            </>
                        ) : null}

                        {task.type == 'form' ? (
                            <div className='task_bottom_panel_button'>
                                {inputValueByBitParent !== null ? (
                                    <a href={inputValueByBitParent} rel="noopener noreferrer" target="_blank" className="button">
                                        {tr[tr.user_lang]['bybitref_link']}
                                    </a>
                                ) : (
                                    <a href={Bybit_ref} rel="noopener noreferrer" target="_blank" className="button">
                                        {tr[tr.user_lang]['bybitref_link']}
                                    </a>
                                )}
                            </div>
                        ) : null}

                        {task.type == 'form' && task.bybit_link == null ? (
                            <>
                                <div className='task_form'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='task_form_row'>
                                            <input
                                                className="task_form_input"
                                                type="text"
                                                id="inputField"
                                                value={inputValue}
                                                placeholder="Enter your UID"
                                                onChange={(e) => setInputValue(e.target.value)}
                                            />

                                            <button
                                                type="submit"
                                                className="task_form_submit"
                                            >Passed</button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        ) : task.type == 'form' && task.bybit_link !== null && task?.bybit_link && task?.bybit_link?.getting_reward == 1 && task?.collect_reward == 1 || succesRevardByBit ? (
                            <div className='task_green_text'>The task is completed!</div>
                        ) : task.type == 'form' && task.bybit_link !== null && task?.bybit_link && task?.bybit_link?.getting_reward == 0 && task?.collect_reward == 1 ? (
                            <div className='task_bottom_panel_button'>
                                <button onClick={() => getRevardBybit(task.id)} className="buttonBig">
                                    Get a reward
                                </button>
                            </div>
                        ) : task.type == 'form' && task.bybit_link !== null && task?.bybit_link && task?.collect_reward == 0 ? (
                            <div className='task_bottom_panel_button'>
                                <button className="buttonBigDisabled">
                                    Get the reward later
                                </button>
                            </div>
                        ) : null}


                        <button className='task_bottom_panel_close' onClick={closePanel}>
                            <img src="/images/close.png" style={{ width: '24px', height: '24px' }} />
                        </button>

                        <div className='task_bottom_panel_description'>
                            {tr.user_lang == 'ru' ? task.description : task.description_en}
                        </div>

                        <div className='task_bottom_panel_awards'>
                            {task.awardspins != '0' ? (
                                <div className='task_bottom_panel_awards_spin'>
                                    +{task.awardspins} scrolls
                                </div>
                            ) : null}
                            {task.awardmonets != '0' ? (
                                <div className='task_bottom_panel_awards_monets'>
                                    +{task.awardmonets} <img src="/images/moneta.png" style={{ width: '40px', height: '30px', verticalAlign: 'bottom' }} />
                                </div>
                            ) : null}
                        </div>

                    </div>
                </SlideBottomPanel>
            ) : null}

            {/* Изменить ссылку ByBit */}
            <Modal
                show={openbybitmodal}
                onHide={handleCloseBybitmodal} centered>
                <div className='modalContentGift'>
                    <button className='buttonClose' onClick={handleCloseBybitmodal}>
                        <img src='images/close.png' />
                    </button>

                    <div className='bybit_modal_body'>
                        <div className='bybit_modal_body_title'>
                            Insert your ByBit referral link
                        </div>
                        <div className='bybit_modal_body_form'>
                            <form onSubmit={handleSubmitByBitform}>
                                <div className='task_form_big'>
                                    <input
                                        className="task_form_input_big"
                                        type="text"
                                        id="inputFieldByBitform"
                                        value={inputValueByBit}
                                        placeholder="https://partner.bybit.com/b/vabank"
                                        onChange={(e) => setInputValueByBit(e.target.value)}
                                    />
                                </div>
                                <div className='task_form_button_wrap'>
                                    <button
                                        type="submit"
                                        className="task_form_submit_big"
                                        disabled={isDisabled}
                                    >Save</button>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </Modal>

            <ToastContainer />
        </>
    );
})

export default Tasks;
