import { makeAutoObservable } from "mobx";


class PlayerStore {

    player = {
        scrolling: 0,
        timezone: '',
        reels: [
            {
                imageSrc: '/images/reel-strip1.png',
                symbols: [
                    { title: 'pepe', position: 100, weight: 8 },
                    { title: 'ton', position: 300, weight: 1 },
                    { title: 'graf', position: 500, weight: 3 },
                    { title: 'doge', position: 700, weight: 4 },
                    { title: 'pira', position: 900, weight: 5 },
                    { title: 'eth', position: 1100, weight: 6 },
                    { title: 'btc', position: 1300, weight: 5 },
                    { title: 'not', position: 1500, weight: 7 },
                    { title: 'rkt', position: 1700, weight: 2 },
                ]
            },
            {
                imageSrc: '/images/reel-strip2.png',
                symbols: [
                    { title: 'doge', position: 100, weight: 4 },
                    { title: 'pira', position: 300, weight: 5 },
                    { title: 'eth', position: 500, weight: 6 },
                    { title: 'btc', position: 700, weight: 5 },
                    { title: 'not', position: 900, weight: 7 },
                    { title: 'rkt', position: 1100, weight: 2 },
                    { title: 'pepe', position: 1300, weight: 8 },
                    { title: 'graf', position: 1500, weight: 3 },
                    { title: 'ton', position: 1700, weight: 1 },
                ]
            },
            {
                imageSrc: '/images/reel-strip3.png',
                symbols: [
                    { title: 'eth', position: 100, weight: 6 },
                    { title: 'btc', position: 300, weight: 5 },
                    { title: 'not', position: 500, weight: 7 },
                    { title: 'rkt', position: 700, weight: 2 },
                    { title: 'pepe', position: 900, weight: 8 },
                    { title: 'graf', position: 1100, weight: 3 },
                    { title: 'ton', position: 1300, weight: 1 },
                    { title: 'doge', position: 1500, weight: 4 },
                    { title: 'pira', position: 1700, weight: 5 }
                ]
            }
        ],
        tguser: null
    }



    //Делаем отслеживаемость
    constructor() {
        makeAutoObservable(this)
    }

    //Добавляем
    editPlayerStore(attr, value) {
        this.player[attr] = value;
    }

    //Метод для полной замены объекта player
    setPlayer(newPlayerData) {
        this.player = { ...newPlayerData };
    }

}

const playerStore = new PlayerStore();
export default playerStore; 